import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { coreUrl } from './index';
import { MapResponse } from '../models/Map';

export const mapAPI = createApi({
  reducerPath: 'mapAPI',
  baseQuery: fetchBaseQuery({ baseUrl: coreUrl }),
  endpoints: (builder) => ({
    fetchDefMap: builder.query<MapResponse, void>({
      query: () => ({
        url: `/maps/def/`,
      }),
    }),
  }),
});

export const { useFetchDefMapQuery } = mapAPI;
