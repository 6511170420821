import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cartActions } from '../store/slices/cart-slice';
import { customerActions } from '../store/slices/customer-slice';
import { settingActions } from '../store/slices/settings-slice';
import { addressActions } from '../store/slices/address-customer-slice';
import { amountGMActions } from '../store/slices/amount-gm';

const actions = {
  ...cartActions,
  ...customerActions,
  ...settingActions,
  ...addressActions,
  ...amountGMActions,
};

export const useActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(actions, dispatch);
};
