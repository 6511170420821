import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { coreUrl } from './index';
import { DomainModel } from '../models/DomainModel';

export const domainAPI = createApi({
  reducerPath: 'domainAPI',
  baseQuery: fetchBaseQuery({ baseUrl: coreUrl }),
  endpoints: (builder) => ({
    fetchDomains: builder.query<DomainModel[], void>({
      query: () => ({
        url: `/domains/`,
      }),
    }),
  }),
});

export const { useFetchDomainsQuery } = domainAPI;
