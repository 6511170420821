import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { coreUrl } from './index';
import { SelectionModel } from '../models/Selection';
import { GetRequestModel } from '../models/GetRequestModel';

export const selectionsAPI = createApi({
  tagTypes: ['Selection'],
  reducerPath: 'selectionsAPI',
  baseQuery: fetchBaseQuery({ baseUrl: coreUrl }),
  endpoints: (builder) => ({
    requestAllSelections: builder.query<SelectionModel[], void>({
      providesTags: ['Selection'],
      query: () => ({
        headers: {
          Accept: 'application/json; version=1',
        },
        url: '/selections/',
      }),
    }),

    requestSelection: builder.query<SelectionModel, GetRequestModel>({
      query: (data) => ({
        url: `/selection/`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useRequestAllSelectionsQuery, useRequestSelectionQuery } = selectionsAPI;
