import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { customerUrl } from './index';
import { CustomerBalance, CustomerModel } from '../models/Customer';
import { AddressModel } from '../models/Address';

export const customerAPI = createApi({
  reducerPath: 'customerAPI',
  baseQuery: fetchBaseQuery({ baseUrl: customerUrl }),
  tagTypes: ['Address'],
  endpoints: (builder) => ({
    requestUser: builder.query<CustomerModel, string>({
      query: (id) => ({
        headers: {
          ptm: 'web',
        },
        url: `/customer/${id}/`,
      }),
    }),
    logoutUser: builder.query<CustomerModel, string>({
      query: (id) => ({
        headers: {
          ptm: 'web',
        },
        url: `/logout/${id}/`,
      }),
    }),

    updateUser: builder.mutation<CustomerModel, CustomerModel>({
      query: (data) => ({
        url: `/customer/${data.uuid}/`,
        method: 'POST',
        body: data,
      }),
    }),
    requestCustomerBalance: builder.query<CustomerBalance, string>({
      query: (id) => ({
        url: `/balance/${id}/`,
      }),
    }),

    requestUserAddresses: builder.query<AddressModel[], string>({
      providesTags: ['Address'],
      query: (id) => ({
        headers: {
          Accept: 'application/json; version=1',
        },
        url: `/addresses/${id}/`,
      }),
    }),

    saveUserAddress: builder.mutation<AddressModel, AddressModel>({
      invalidatesTags: ['Address'],
      query: (address) => ({
        headers: {
          Accept: 'application/json; version=1',
        },
        url: `/update_address/${address.uuid}/`,
        method: 'POST',
        body: address,
      }),
    }),

    createUserAddress: builder.mutation<AddressModel, { address: AddressModel; customerId: string }>({
      invalidatesTags: ['Address'],
      query: ({ address, customerId }) => ({
        headers: {
          Accept: 'application/json; version=1',
        },
        url: `/addresses/${customerId}/`,
        method: 'POST',
        body: address,
      }),
    }),

    deleteUserAddress: builder.mutation<AddressModel, string>({
      invalidatesTags: ['Address'],
      query: (id) => ({
        headers: {
          Accept: 'application/json; version=1',
        },
        url: `/remove_address/${id}/`,
      }),
    }),
  }),
});

export const {
  useLazyRequestUserQuery,
  useUpdateUserMutation,
  useLazyRequestUserAddressesQuery,
  useCreateUserAddressMutation,
  useSaveUserAddressMutation,
  useDeleteUserAddressMutation,
  useRequestUserAddressesQuery,
  useLazyRequestCustomerBalanceQuery,
  useLazyLogoutUserQuery,
} = customerAPI;
