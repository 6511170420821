import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { promotionAPI } from '../services/promotion-service';
import { setupListeners } from '@reduxjs/toolkit/query';
import { cartReducer } from './slices/cart-slice';
import { authAPI } from '../services/auth-service';
import { customerReducer } from './slices/customer-slice';
import { productAPI } from '../services/product-service';
import { customerAPI } from '../services/customer-service';
import { mapAPI } from '../services/map-service';
import { shopAPI } from '../services/shop-service';
import { orderAPI } from '../services/order-service';
import { domainAPI } from '../services/domain-service';
import { selectionsAPI } from '../services/selections-service';
import { groupAPI } from '../services/group-service';
import { appealAPI } from '../services/appeal-service';
import { deliveryTypeAPI } from '../services/delivery-service';
import { paymentTypeAPI } from '../services/payment-service';
import { advicedProductAPI } from '../services/adviced-service';
import { seoAPI } from '../services/seo-service';
import { addressReducer } from './slices/address-customer-slice';
import { amountGMReducer } from './slices/amount-gm';

const rootReducer = combineReducers({
  [promotionAPI.reducerPath]: promotionAPI.reducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [productAPI.reducerPath]: productAPI.reducer,
  [customerAPI.reducerPath]: customerAPI.reducer,
  [mapAPI.reducerPath]: mapAPI.reducer,
  [shopAPI.reducerPath]: shopAPI.reducer,
  [orderAPI.reducerPath]: orderAPI.reducer,
  [domainAPI.reducerPath]: domainAPI.reducer,
  [selectionsAPI.reducerPath]: selectionsAPI.reducer,
  [groupAPI.reducerPath]: groupAPI.reducer,
  [appealAPI.reducerPath]: appealAPI.reducer,
  [deliveryTypeAPI.reducerPath]: deliveryTypeAPI.reducer,
  [paymentTypeAPI.reducerPath]: paymentTypeAPI.reducer,
  [advicedProductAPI.reducerPath]: advicedProductAPI.reducer,
  [seoAPI.reducerPath]: seoAPI.reducer,
  cart: cartReducer,
  customer: customerReducer,
  address: addressReducer,
  amountGM: amountGMReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      promotionAPI.middleware,
      authAPI.middleware,
      productAPI.middleware,
      customerAPI.middleware,
      mapAPI.middleware,
      shopAPI.middleware,
      orderAPI.middleware,
      domainAPI.middleware,
      selectionsAPI.middleware,
      groupAPI.middleware,
      appealAPI.middleware,
      deliveryTypeAPI.middleware,
      paymentTypeAPI.middleware,
      advicedProductAPI.middleware,
      seoAPI.middleware,
    ),
});

setupListeners(store.dispatch);

export type GetRootState = typeof store.getState;

export type RootState = ReturnType<GetRootState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
