import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { coreUrl } from './index';
import { GroupModel } from '../models/Group';
import { CartProductModel, ProductModel } from '../models/Product';

export const advicedProductAPI = createApi({
  reducerPath: 'advicedProductAPI',
  baseQuery: fetchBaseQuery({ baseUrl: coreUrl }),
  endpoints: (builder) => ({
    requestAdvicedProduct: builder.query<CartProductModel[], string>({
      query: (id) => ({
        headers: {
          Accept: 'application/json; version=front',
        },
        url: `/product/${id}/adviced/`,
      }),
    }),
  }),
});

export const { useLazyRequestAdvicedProductQuery } = advicedProductAPI;
