import { FC } from 'react';
import styled from 'styled-components';
import LogoIcon from '../icons/LogoIcon';

const Loader: FC = () => {
  return (
    <Overlay>
      <Wrapper>
        <LogoIcon />
        <Text>Идет загрузка...</Text>
      </Wrapper>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  z-index: 102;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 60px;
    height: 60px;
    animation: spin 3s linear infinite;
    margin-bottom: 10px;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

const Text = styled.span`
  display: block;
  color: ${(props) => props.theme.primary};
  font-size: 16px;
`;

export default Loader;
