import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { appealsUrl } from './index';
import { AppealModel } from '../models/Appeal';

export const appealAPI = createApi({
  reducerPath: 'appealAPI',
  baseQuery: fetchBaseQuery({ baseUrl: appealsUrl }),
  endpoints: (builder) => ({
    addAppeal: builder.mutation<AppealModel, AppealModel>({
      query: (data) => ({
        url: `/add_appeal/`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useAddAppealMutation } = appealAPI;
