import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerModel } from '../../models/Customer';
import { AddressModel } from '../../models/Address';

interface CustomerState {
  customer: CustomerModel | null;
  address: AddressModel[];
}

// const localStorageCustomer = JSON.parse(localStorage.getItem('customer') ?? '');

const initialState: CustomerState = {
  customer: localStorage.getItem('customer') ? JSON.parse(localStorage.getItem('customer') ?? 'null') : null,
  // customer: localStorageCustomer ?? null,
  address: [],
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomer: (state: CustomerState, action: PayloadAction<CustomerModel>) => {
      state.customer = action.payload;

      localStorage.setItem('customer', JSON.stringify(action.payload));
    },
    resetCustomer: (state: CustomerState) => {
      state.customer = null;

      localStorage.setItem('customer', '');
    },
    setAddress: (state: CustomerState, action: PayloadAction<AddressModel[]>) => {
      state.address = action.payload;
    },
  },
});

export const customerActions = customerSlice.actions;

export const customerReducer = customerSlice.reducer;
