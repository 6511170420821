import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {coreUrl} from './index';

export interface ShopModel {
    uuid:string;
    name: string;
    pickup: boolean;
    phone: string;
    date_work: string;
    time_work: string;
    address: string;
    latitude: number;
    longitude: number
}

export const shopAPI = createApi({
  reducerPath: 'shopAPI',
  baseQuery: fetchBaseQuery({ baseUrl: coreUrl }),
  endpoints: (builder) => ({
    fetchShop: builder.query<ShopModel[], void>({
      query: () => ({
        url: `/shops/`,
      }),
    }),
  }),
});

export const { useFetchShopQuery } = shopAPI;
