import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { coreUrl } from './index';
import { GetSEORequestModel } from '../models/GetRequestModel';
import { SEOModel } from '../models/SEO';

export const seoAPI = createApi({
  reducerPath: 'seoAPI',
  baseQuery: fetchBaseQuery({ baseUrl: coreUrl }),
  endpoints: (builder) => ({
    requestSEO: builder.query<SEOModel, GetSEORequestModel>({
      query: (data) => ({
        url: `/seo/`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json; version=front',
          domain: window.location.host,
        },
      }),
    }),
  }),
});

export const { useRequestSEOQuery, useLazyRequestSEOQuery } = seoAPI;
