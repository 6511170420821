import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerModel } from '../../models/Customer';
import { AddressModel } from '../../models/Address';
import { SEOModel } from '../../models/SEO';
import { DomainModel } from '../../models/DomainModel';

interface SettingsState {
  domain: DomainModel;
  seo: SEOModel | null;
}

const initialState: SettingsState = {
  // @ts-ignore
  domain: { domain: window.location.hostname, name: window.location.hostname },
  seo: localStorage.getItem('seo') ? JSON.parse(localStorage.getItem('seo') ?? '{}') : null,
};

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setDomain: (state: SettingsState, action: PayloadAction<DomainModel>) => {
      if (action.payload.name != state.domain.name || action.payload.domain != state.domain.domain) {
        state.domain = action.payload;
      }

      localStorage.setItem('domain', JSON.stringify(action.payload));
    },
    setSEO: (state: SettingsState, action: PayloadAction<SEOModel>) => {
      state.seo = action.payload;

      localStorage.setItem('seo', JSON.stringify(action.payload));
    },
  },
});

export const settingActions = settingSlice.actions;

export const settingReducer = settingSlice.reducer;
