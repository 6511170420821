import { FC, useEffect } from 'react';
import { useRequestSEOQuery } from '../../services/seo-service';
import isEmpty from 'lodash/isEmpty';
import { Helmet } from 'react-helmet';
import { useActions } from '../../hooks/useActions';

interface HelmetSEOProps {
  uuid?: string;
  url?: string;
  slug?: string;
  product?: string;
  group?: string;
  domain?: string;
  selection?: string;
  text?: (value: string) => void;
}

export const HelmetSEO: FC<HelmetSEOProps> = ({ uuid, url, slug, product, group, domain, selection, text }) => {
  const { setSEO } = useActions();
  const { data, isLoading, isError } = useRequestSEOQuery({
    uuid: uuid,
    url: url,
    slug: slug,
    product: product,
    group: group,
    domain: domain,
    selection: selection,
  });
  useEffect(() => {
    if (data) {
      setSEO({ ...data });
      if (text) {
        text(data.text);
      }
    }
  }, [data]);
  if (isEmpty(data)) return null;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{data.title}</title>
      <meta name="keywords" content={data.keywords} />
      <meta name="text" content={data.text} />
      <meta name="description" content={data.description} />
      <meta name="apple-mobile-web-app-title" content="TakeEat" />
      <meta name="application-name" content="TakeEat" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={data.link} />
      <meta property="og:title" content={data.title} />
      <meta property="og:description" content={data.description} />
      (domain && <meta property="og:url" content={`https://${domain}/`} />)
    </Helmet>
  );
};
