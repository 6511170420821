import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { coreUrl } from './index';
import { Promotion } from '../models/Promotion';

export const promotionAPI = createApi({
  reducerPath: 'promotionAPI',
  baseQuery: fetchBaseQuery({ baseUrl: coreUrl }),
  endpoints: (builder) => ({
    fetchAllPromotions: builder.query<Promotion[], void>({
      query: () => ({
        url: `/news/`,
      }),
    }),
  }),
});

export const { useLazyFetchAllPromotionsQuery, useFetchAllPromotionsQuery } = promotionAPI;
