import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { coreUrl } from './index';
import { GroupMenu, GroupModel } from '../models/Group';
import { GetRequestModel } from '../models/GetRequestModel';

export const groupAPI = createApi({
  reducerPath: 'groupAPI',
  baseQuery: fetchBaseQuery({ baseUrl: coreUrl }),
  endpoints: (builder) => ({
    requestGroupMenu: builder.query<GroupMenu[], void>({
      query: () => ({
        headers: {
          Accept: 'application/json; version=1',
        },
        url: '/group/menu/',
      }),
    }),
    requestGroupHome: builder.query<GroupModel[], void>({
      query: () => ({
        headers: {
          Accept: 'application/json; version=1',
        },
        url: '/group/home/',
      }),
    }),
    requestGroup: builder.mutation<GroupModel, GetRequestModel>({
      query: (data) => ({
        url: `/group/`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json; version=front',
        },
      }),
    }),
  }),
});

export const {
  useRequestGroupMenuQuery,
  useRequestGroupMutation,
  useLazyRequestGroupMenuQuery,
  useLazyRequestGroupHomeQuery,
  useRequestGroupHomeQuery,
} = groupAPI;
