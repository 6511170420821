import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AGMDState {
  id: string;
  productId: string;
  maxAmount: number;
  minAmount: number;
  amount: number;
}

interface AmountGMState {
  dataGM: AGMDState[];
}

// const localStorageCustomer = JSON.parse(localStorage.getItem('customer') ?? '');

const initialState: AmountGMState = {
  dataGM: localStorage.getItem('dataGM') ? JSON.parse(localStorage.getItem('dataGM') ?? 'null') : [],
};

const amountGMSlice = createSlice({
  name: 'amount',
  initialState,
  reducers: {
    setAmount: (state: AmountGMState, action: PayloadAction<AGMDState>) => {
      const itemIndex = state.dataGM.findIndex((item) => item.id === action.payload.id);

      const item = state.dataGM[itemIndex];
      item.amount = action.payload.amount;

      localStorage.setItem('amount', JSON.stringify({ dataGM: state.dataGM }));
    },
    addDataGM: (state: AmountGMState, action: PayloadAction<AGMDState[]>) => {
      state.dataGM = [...state.dataGM, ...action.payload];

      localStorage.setItem('amount', JSON.stringify({ dataGM: state.dataGM }));
    },
    resetDataGM: (state: AmountGMState) => {
      state.dataGM = [];
      localStorage.removeItem('amount');
    },
  },
});

export const amountGMActions = amountGMSlice.actions;

export const amountGMReducer = amountGMSlice.reducer;
