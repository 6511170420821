import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CartProductModel } from '../../models/Product';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
interface CartState {
  products: CartProductModel[];
  lastItemIndex: number | null;
  count: number;
  total: number;
}

const localStorageCart = JSON.parse(localStorage.getItem('cart') ?? '{}');

const initialState: CartState = {
  products: localStorageCart.products ?? [],
  lastItemIndex: localStorageCart.lastItemIndex ?? null,
  count: localStorageCart.count ?? 0,
  total: localStorageCart.total ?? 0,
};
// const initialState: CartState = {
//   products: [],
//   count: 0,
//   total: 0,
// };

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state: CartState, action: PayloadAction<CartProductModel>) => {
      let item: CartProductModel;
      if (state.lastItemIndex != null && state.products[state.lastItemIndex].uuid != action.payload.uuid) {
        state.lastItemIndex = null;
      }
      if (action.payload.id) {
        const itemIndex = state.products.findIndex((item, index) => item.id === action.payload.id);

        item = state.products[itemIndex];
      } else if (
        state.lastItemIndex != null &&
        action.payload.index == undefined &&
        state.products[state.lastItemIndex].uuid == action.payload.uuid
      ) {
        item = state.products[state.lastItemIndex];
        item.index = state.lastItemIndex;
      } else if (action.payload.index != undefined && !isEmpty(action.payload.modifiers)) {
        item = state.products[-1];
      } else if (action.payload.index != undefined) {
        item = state.products[action.payload.index];
      } else {
        const itemIndex = state.products.findIndex(
          (item, index) =>
            (item.uuid === action.payload.uuid && isEmpty(action.payload.modifiers)) ||
            (item.index === action.payload.index &&
              item.uuid === action.payload.uuid &&
              isEmpty(action.payload.modifiers)),
        );

        item = state.products[itemIndex];
        if (itemIndex != -1) {
          item.index = itemIndex;
        }
      }

      if (item) {
        state.products[item.index ?? state.lastItemIndex] = {
          ...item,
          amount: action.payload.amount,
          index: item.index,
          total:
            action.payload.amount *
            (item.price + action.payload.modifiers.reduce((acc, value) => acc + value.price * value.amount, 0)),
          modifiers: action.payload.modifiers,
          modifiers_uuid: action.payload.modifiers.map((modifier) => modifier.uuid),
          modifiers_name: action.payload.modifiers.map((modifier) => modifier.name).join(', '),
        };
        state.count = state.count - item.amount + action.payload.amount;
        state.total =
          state.total -
          item.total +
          action.payload.amount *
            (item.price + action.payload.modifiers.reduce((acc, value) => acc + value.price * value.amount, 0));
      } else {
        state.products = [
          ...state.products,
          {
            ...action.payload,
            index: state.products.length,
            id: uuidv4().toString(),
            amount: 1,
            total:
              action.payload.price +
              action.payload.modifiers.reduce((acc, value) => acc + value.price * value.amount, 0),
            modifiers: action.payload.modifiers,
            modifiers_uuid: action.payload.modifiers.map((modifier) => modifier.uuid),
            modifiers_name: action.payload.modifiers.map((modifier) => modifier.name).join(', '),
          },
        ];
        state.lastItemIndex = state.products.length - 1;
        state.count = state.count + 1;
        state.total =
          state.total +
          action.payload.price +
          action.payload.modifiers.reduce((acc, value) => acc + value.price * value.amount, 0);
      }
      localStorage.setItem(
        'cart',
        JSON.stringify({
          products: state.products,
          count: state.count,
          total: state.total,
          lastItemIndex: state.lastItemIndex,
        }),
      );
    },

    removeFromCart: (state: CartState, action: PayloadAction<CartProductModel>) => {
      let item: CartProductModel;
      if (
        state.lastItemIndex != null &&
        state.products[state.lastItemIndex] &&
        state.products[state.lastItemIndex].uuid != action.payload.uuid
      ) {
        state.lastItemIndex = null;
      }
      if (action.payload.id) {
        const itemIndex = state.products.findIndex((item, index) => item.id === action.payload.id);

        item = state.products[itemIndex];
      } else if (action.payload.index != undefined) {
        item = state.products[action.payload.index];
      } else if (
        state.lastItemIndex != null &&
        action.payload.index == undefined &&
        state.products[state.lastItemIndex].uuid == action.payload.uuid
      ) {
        item = state.products[state.lastItemIndex];
        item.index = state.lastItemIndex;
      } else {
        const itemIndex = state.products.findIndex(
          (item, index) =>
            (item.uuid === action.payload.uuid && isEmpty(action.payload.modifiers)) ||
            (item.index === action.payload.index &&
              item.uuid === action.payload.uuid &&
              isEmpty(action.payload.modifiers)),
        );

        item = state.products[itemIndex];
        if (itemIndex != -1) {
          item.index = itemIndex;
        }
      }
      if (item) {
        state.products[item.index] = {
          ...item,
          amount: action.payload.amount,
          total:
            action.payload.amount *
            (item.price + action.payload.modifiers.reduce((acc, value) => acc + value.price * value.amount, 0)),
        };
      }

      if (action.payload.amount === 0) {
        state.products = state.products.filter(
          (product, index) => !(index == action.payload.index),
          // !(
          //   product.uuid === action.payload.uuid &&
          //   isEqual(
          //     product.modifiers_uuid,
          //     action.payload.modifiers.map((modifier) => modifier.uuid),
          //   )
          // ),
        );
        for (let i = 0; i < state.products.length; i++) {
          state.products[i].index = i;
        }

        state.total =
          state.total -
          (action.payload.price + action.payload.modifiers.reduce((acc, value) => acc + value.price * value.amount, 0));
        // state.products = products;
      } else {
        state.total =
          state.total -
          action.payload.total +
          action.payload.amount *
            (item.price + action.payload.modifiers.reduce((acc, value) => acc + value.price * value.amount, 0));
      }

      state.count -= 1;
      if (state.count <= 0) {
        state.total = 0;
        state.count = 0;
        state.lastItemIndex = null;
      }
      localStorage.setItem(
        'cart',
        JSON.stringify({
          products: state.products,
          count: state.count,
          total: state.total,
          lastItemIndex: state.lastItemIndex,
        }),
      );
    },

    changeInCart: (state: CartState, action: PayloadAction<{ count: number; product: CartProductModel }>) => {
      const itemIndex = state.products.findIndex(
        (item) =>
          item.uuid === action.payload.product.uuid &&
          isEqual(item.modifiers_uuid, action.payload.product.modifiers_uuid),
      );
      const item = state.products[itemIndex];
      if (item) {
        state.products[itemIndex] = {
          ...item,
          amount: action.payload.count,
          total:
            action.payload.count *
            (action.payload.product.price +
              action.payload.product.modifiers.reduce((acc, value) => acc + value.price * value.amount, 0)),
        };
      }

      state.count = state.count - item.amount + action.payload.count;
      state.total =
        state.total -
        item.total +
        action.payload.count *
          (action.payload.product.price +
            action.payload.product.modifiers.reduce((acc, value) => acc + value.price * value.amount, 0));
      localStorage.setItem(
        'cart',
        JSON.stringify({ products: state.products, count: state.count, total: state.total }),
      );
    },
    setLastItemIndex: (state: CartState, action: PayloadAction<number | null>) => {
      state.lastItemIndex = action.payload;
      localStorage.setItem('cart', JSON.stringify({ ...state, lastItemIndex: state.lastItemIndex }));
    },
    clearCart: (state: CartState) => {
      state.products = [];
      state.count = 0;
      state.total = 0;

      localStorage.setItem('cart', JSON.stringify({ products: [], count: 0, total: 0 }));
    },
    clearLastItemIndex: (state: CartState) => {
      state.lastItemIndex = null;
      localStorage.setItem('cart', JSON.stringify({ ...state, lastItemIndex: null }));
    },
  },
});

export const cartActions = cartSlice.actions;

export const cartReducer = cartSlice.reducer;
