import React, { FC, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { routes } from '../../constants/routes';
// import {
//   AboutPage,
//   BonusSystemPage,
//   CatalogPage,
//   ContactsPage,
//   DeliveryPage,
//   HomePage,
//   NotFoundPage,
//   PersonalPage,
//   PolicyPage,
//   PromotionPage,
//   PromotionsPage,
// } from '../../pages';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from '../../constants/theme';
// import SelectionPage from '../../pages/SelectionPage';
import { useActions } from '../../hooks/useActions';
import isEmpty from 'lodash/isEmpty';
import { useLazyRequestUserQuery } from '../../services/customer-service';
import { useAppSelector } from '../../store/store';
import Loader from '../loader/Loader';
import { useCookies } from 'react-cookie';
import { cryptCc, deCryptCc } from '../../utils/crypt-cc';
import { add } from 'date-fns';

const AboutPage = React.lazy(() => import('../../pages/AboutPage'));
const BonusSystemPage = React.lazy(() => import('../../pages/BonusSystemPage'));
const CatalogPage = React.lazy(() => import('../../pages/CatalogPage'));
const ContactsPage = React.lazy(() => import('../../pages/ContactsPage'));
const DeliveryPage = React.lazy(() => import('../../pages/DeliveryPage'));
const HomePage = React.lazy(() => import('../../pages/HomePage'));
const NotFoundPage = React.lazy(() => import('../../pages/NotFoundPage'));
const PersonalPage = React.lazy(() => import('../../pages/PersonalPage'));
const PolicyPage = React.lazy(() => import('../../pages/PolicyPage'));
const PromotionPage = React.lazy(() => import('../../pages/promotions/PromotionPage'));
const PromotionsPage = React.lazy(() => import('../../pages/promotions/PromotionsPage'));

const Address = React.lazy(() => import('../address/Address'));
// import { Address } from '../address';
const CartHistory = React.lazy(() => import('../cart/cart-history/CartHistory'));
// import { CartHistory } from '../cart';
const SelectionPage = React.lazy(() => import('../../pages/SelectionPage'));
import { HelmetSEO } from '../seo/HelmetSEO';
const App: FC = () => {
  const { setCustomer, resetCustomer } = useActions();
  const { customer } = useAppSelector((state) => state.customer);
  const [requestCustomer, { data: dataCustomer, isLoading: isCustomerLoading }] = useLazyRequestUserQuery();
  const [cookies, setCookie, removeCookie] = useCookies(['acaid']);

  useEffect(() => {
    if (!isEmpty(customer) && !isEmpty(customer!.uuid)) {
      requestCustomer(customer!.uuid!);
    }
  }, [customer]);
  useEffect(() => {
    if (cookies.acaid && cookies.acaid !== '') {
      try {
        let cus = JSON.parse(deCryptCc(cookies.acaid));
        setCustomer(cus);
      } catch (e) {
        console.log(e);
        document.cookie = 'acaid=; expires=0; path=/; domain=.takeeat.ru;';
      }
    } else if ((!cookies.acaid || cookies.acaid === 'none') && !isEmpty(dataCustomer)) {
      let startInterval = add(Date.now(), { months: 1 });
      setCookie('acaid', cryptCc(JSON.stringify(dataCustomer)), {
        path: '/',
        domain: '.takeeat.ru',
        expires: startInterval,
      });
    } else if (!isEmpty(dataCustomer)) {
      resetCustomer();
      // setCustomer(dataCustomer);
      let startInterval = add(Date.now(), { months: 1 });
      setCookie('acaid', cryptCc(JSON.stringify(dataCustomer)), {
        path: '/',
        domain: '.takeeat.ru',
        expires: startInterval,
      });
    }
  }, [dataCustomer]);
  return (
    <ThemeProvider theme={theme}>
      <HelmetSEO domain={window.location.hostname} />
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path={routes.HOME}>
            <Route
              index
              element={
                <React.Suspense fallback={<Loader />}>
                  <HomePage />
                </React.Suspense>
              }
            />
            <Route
              path={routes.CONTACTS}
              element={
                <React.Suspense fallback={<></>}>
                  <ContactsPage />
                </React.Suspense>
              }
            />
            <Route
              path={routes.CATALOG + '/:id'}
              element={
                <React.Suspense fallback={<></>}>
                  <CatalogPage />
                </React.Suspense>
              }
            />
            <Route
              path={routes.SELECTION + '/:id'}
              element={
                <React.Suspense fallback={<></>}>
                  <SelectionPage />
                </React.Suspense>
              }
            />
            <Route
              path={routes.PERSONAL}
              element={
                <React.Suspense fallback={<></>}>
                  <PersonalPage />
                </React.Suspense>
              }
            >
              <Route
                path={routes.ADDRESS}
                element={
                  <React.Suspense fallback={<></>}>
                    <Address />
                  </React.Suspense>
                }
              />
              <Route
                path={routes.HISTORY}
                element={
                  <React.Suspense fallback={<></>}>
                    <CartHistory />
                  </React.Suspense>
                }
              />
            </Route>
            <Route
              path={routes.ABOUT}
              element={
                <React.Suspense fallback={<Loader />}>
                  <AboutPage />
                </React.Suspense>
              }
            />
            <Route
              path={routes.BONUS_SYSTEM}
              element={
                <React.Suspense fallback={<Loader />}>
                  <BonusSystemPage />
                </React.Suspense>
              }
            />
            <Route
              path={routes.DELIVERY}
              element={
                <React.Suspense fallback={<Loader />}>
                  <DeliveryPage />
                </React.Suspense>
              }
            />
            <Route
              path={routes.PROMOTIONS}
              element={
                <React.Suspense fallback={<Loader />}>
                  <PromotionsPage />
                </React.Suspense>
              }
            />
            <Route
              path={routes.PROMOTIONS + '/:id'}
              element={
                <React.Suspense fallback={<Loader />}>
                  <PromotionPage />
                </React.Suspense>
              }
            />
            <Route
              path={routes.POLICY}
              element={
                <React.Suspense fallback={<Loader />}>
                  <PolicyPage />
                </React.Suspense>
              }
            />
            <Route
              path="*"
              element={
                <React.Suspense fallback={<Loader />}>
                  <NotFoundPage />
                </React.Suspense>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
