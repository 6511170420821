import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { orderUrl } from './index';
import { OrderHistoryModel, OrderInfoModel } from '../models/Order';

export const orderAPI = createApi({
  reducerPath: 'orderAPI',
  baseQuery: fetchBaseQuery({ baseUrl: orderUrl }),
  endpoints: (builder) => ({
    requestOrders: builder.query<OrderHistoryModel[], string>({
      query: (id) => ({
        headers: {
          Accept: 'application/json; version=1',
        },
        url: `/history/${id}`,
      }),
    }),

    createOrder: builder.query<OrderInfoModel, OrderInfoModel>({
      query: (body) => ({
        headers: {
          Accept: 'application/json; version=web',
        },
        url: `/attach/`,
        body: body,
        method: 'POST',
      }),
    }),
  }),
});

export const { useLazyCreateOrderQuery, useLazyRequestOrdersQuery } = orderAPI;
