import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MapAddress } from '../../models/Address';
import isEmpty from 'lodash/isEmpty';

interface AddressState {
  address: MapAddress | null;
  addresses: MapAddress[];
}

// const localStorageCustomer = JSON.parse(localStorage.getItem('customer') ?? '');

const initialState: AddressState = {
  address: localStorage.getItem('address') ? JSON.parse(localStorage.getItem('address') ?? 'null') : null,
  addresses: localStorage.getItem('addresses') ? JSON.parse(localStorage.getItem('addresses') ?? '[]') : [],
  // customer: localStorageCustomer ?? null,
};

const addressSlice = createSlice({
  name: 'ADL',
  initialState,
  reducers: {
    setGuestAddress: (state: AddressState, action: PayloadAction<MapAddress>) => {
      action.payload.anon = true;
      action.payload.city = typeof action.payload.city == 'object' ? action.payload.city[0] : action.payload.city;
      state.address = action.payload;
      state.addresses.push(action.payload);
      for (let i = 0; i < state.addresses.length; i++) {
        state.addresses[i].index = i;
      }
      localStorage.setItem('address', JSON.stringify(action.payload));
      localStorage.setItem('addresses', JSON.stringify(state.addresses));
    },
    setAddresses: (state: AddressState, action: PayloadAction<MapAddress[]>) => {
      state.addresses = state.addresses.filter((addr) => addr.anon);
      if (!isEmpty(action.payload)) {
        action.payload.forEach((addr) => {
          // TODO(kebrick): Код для измения обьекта без перебора по индексам, рабочий!
          // const itemIndex = state.addresses.findIndex((item, index) => !isEmpty(item.uuid) && item.uuid == addr.uuid);
          //
          // if (itemIndex != -1) {
          //   if (state.addresses.length - 1 > itemIndex) {
          //     state.addresses = [
          //       ...state.addresses.slice(0, itemIndex),
          //       { ...addr, index: itemIndex, location: '' },
          //       ...state.addresses.slice(itemIndex + 1, state.addresses.length - 1),
          //     ];
          //   } else {
          //     state.addresses = [...state.addresses.slice(0, itemIndex), { ...addr, index: itemIndex, location: '' }];
          //   }
          // } else {
          //   state.addresses = [...state.addresses, { ...addr, index: itemIndex, location: '' }];
          // }
          state.addresses = [...state.addresses, { ...addr, index: -1, location: '', anon: false }];
        });
      }
      for (let i = 0; i < state.addresses.length; i++) {
        state.addresses[i].index = i;
      }
      localStorage.setItem('addresses', JSON.stringify(state.addresses));
    },
    editAddress: (state: AddressState, action: PayloadAction<MapAddress>) => {
      state.addresses[action.payload.index] = action.payload;

      localStorage.setItem('addresses', JSON.stringify(state.addresses));
    },
    resetAuthAddress: (state: AddressState) => {
      state.addresses = state.addresses.filter((addr) => {
        addr.uuid === '';
      });

      localStorage.setItem('addresses', JSON.stringify(state.addresses));
    },
    removeGuestAddress: (state: AddressState, action: PayloadAction<MapAddress>) => {
      console.log(action.payload.index);
      state.addresses = state.addresses.filter((addr) => addr.index != action.payload.index);
      for (let i = 0; i < state.addresses.length; i++) {
        state.addresses[i].index = i;
      }
      localStorage.setItem('addresses', JSON.stringify(state.addresses));
    },
  },
});

export const addressActions = addressSlice.actions;

export const addressReducer = addressSlice.reducer;
