import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { coreUrl } from './index';
import { DeliveryTypeModel } from '../models/TypeDeliveryModel';

export const deliveryTypeAPI = createApi({
  reducerPath: 'deliveryTypeAPI',
  baseQuery: fetchBaseQuery({ baseUrl: coreUrl }),
  endpoints: (builder) => ({
    fetchDeliveryType: builder.query<DeliveryTypeModel[], void>({
      query: () => ({
        url: `/delivery_types/`,
      }),
    }),
  }),
});

export const { useFetchDeliveryTypeQuery } = deliveryTypeAPI;
