import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { authUrl } from './index';

interface RequestCallData {
  org: string;
  phone: string;
}

interface CheckCodeData extends RequestCallData {
  code: string;
}

interface AuthResponse {
  succeed: boolean;
  send: string;
  message: string;
  time_repeat: number;
  customer: string;
  iikoStatus: boolean;
}

export const authAPI = createApi({
  reducerPath: 'authAPI',
  baseQuery: fetchBaseQuery({ baseUrl: authUrl }),
  endpoints: (builder) => ({
    requestCall: builder.mutation<AuthResponse, RequestCallData>({
      query: (data) => ({
        url: `/call`,
        method: 'POST',
        body: data,
      }),
    }),

    checkCode: builder.mutation<AuthResponse, CheckCodeData>({
      query: (data) => ({
        url: `/check`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useRequestCallMutation, useCheckCodeMutation } = authAPI;
