import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { coreUrl } from './index';
import { ProductModel } from '../models/Product';
import { GroupModel } from '../models/Group';

export const productAPI = createApi({
  reducerPath: 'productAPI',
  baseQuery: fetchBaseQuery({ baseUrl: coreUrl }),
  endpoints: (builder) => ({
    requestProducts: builder.query<GroupModel[], void>({
      query: () => ({
        headers: {
          Accept: 'application/json; version=front',
        },
        url: '/products/',
      }),
    }),
  }),
});

export const { useRequestProductsQuery } = productAPI;
