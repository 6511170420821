import styled, { createGlobalStyle, css, DefaultTheme } from 'styled-components';

export const deviceSizes = {
  mobileS: '320px',
  mobileM: '360px',
  mobileL: '480px',
  tablet: '769px',
  laptop: '1025px',
  desktop: '1200px',
};

export const inputStyle = css`
  padding: 10px 20px;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #b2b2b2;
  border-radius: ${(props) => props.theme.borderRadius};

  &:focus,
  &:hover {
    border: 1px solid ${(props) => props.theme.primary};
    box-shadow: none;
  }
`;

export const substrateStyle = css`
  background-color: ${(props) => props.theme.secondary.purple};
  border: 1px solid ${(props) => props.theme.additional.borderColor};
  border-radius: ${(props) => props.theme.borderRadius};
`;

export const mobileSubstrateStyle = css`
  border: 0;
  border-radius: 0;
  border-top: 1px solid ${(props) => props.theme.additional.borderColor};
  border-bottom: 1px solid ${(props) => props.theme.additional.borderColor};
`;

export const List = styled.ul`
  list-style: disc;
`;

export const ListItem = styled.li`
  list-style-type: none;
  margin: 0 0 10px;
  padding: 0 0 0 25px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    display: block;
    width: 6px;
    height: 6px;
    background: #ac79e0;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    z-index: 100;
    border-radius: 25px;
  }
`;

export const ModalDescription = styled.span`
  display: block;
  margin-bottom: 30px;
`;

export const DrawerTitle = styled.span`
  display: block;
  margin-bottom: 40px;
  font-family: ${(props) => props.theme.fonts[1]};
  font-weight: 700;
  font-size: 36px;

  @media screen {
    @media (max-width: ${deviceSizes.tablet}) {
      margin-bottom: 30px;
      font-size: 30px;
      line-height: 39px;
    }
  }
`;

export const TabsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px;
  ${substrateStyle};
  border-top-left-radius: 0;

  .field-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    width: 100%;
    max-width: 380px;
  }

  .btn {
    margin-top: 30px;
  }

  @media screen {
    @media (max-width: ${deviceSizes.mobileL}) {
      ${mobileSubstrateStyle};

      .field-wrapper {
        max-width: unset;
      }
    }
  }
`;

export const DrawerItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const DrawerItemTitle = styled.span`
  margin-bottom: 10px;
  font-family: ${(props) => props.theme.fonts[1]};
  font-weight: 700;
  font-size: 20px;
`;

export const GlobalStyle = createGlobalStyle`
  #root {
    height: 100%;  
  }
  
  .container {
    display: flex;
    flex-wrap: wrap;
    max-width: ${(props) => props.theme.maxWidth};
    margin: 0 auto;
  }
  
  .ant-typography {
    color: ${(props) => props.theme.common};
  }

  h1 {
    display: block;
    font-family: ${(props) => props.theme.fonts[1]};
    font-size: 36px;
    font-weight: ${(props) => props.theme.components.h1.fontWeight};
    color: ${(props) => props.theme.common};
    margin-right: auto;
  }

  h3 {
    font-family: ${(props) => props.theme.fonts[0]};
    font-size: 24px;
    font-weight: ${(props) => props.theme.components.h3.fontWeight};
    color: ${(props) => props.theme.common}
  }

  h4 {
    font-family: ${(props) => props.theme.fonts[0]};
    font-size: 20px;
  }

  a.ant-typography, a {
    color: ${(props: any) => props.theme.primary};
    transition: 0.2s ease-in-out;

    &:hover {
      color: ${(props: any) => props.theme.primary};
      opacity: .5;
    }
  }

  .ant-btn-link {
    margin: 0;
    padding: 0;
    height: unset;
    font-weight: 500;
    border: 0;
    color: ${(props: any) => props.theme.primary};

    &:hover {
      color: ${(props: any) => props.theme.primary};
      opacity: .5;
    }

    &:focus {
      color: ${(props: any) => props.theme.primary};
    }
  }

  .ant-modal-wrap {
    padding: 60px 0;
    
    &.sale-modal-wrap {
      top: unset;
      bottom: 30px;
      right: 20px;
      left: unset;
      display: block;
      padding: 0;
      overflow: unset;
    }

    @media screen {
      @media (max-width: ${deviceSizes.tablet}) {
        padding: 0;

        &.sale-modal-wrap {
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
  
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
    height: unset;
    color: #ffffff;
    font-weight: 500;
    border: 1px solid ${(props) => props.theme.primary};
    border-radius: ${(props) => props.theme.borderRadius};

    &:hover,
    &:focus {
      color: ${(props) => props.theme.primary};
      border-color: ${(props) => props.theme.primary};
      background-color: ${(props) => props.theme.secondary.purple};
      opacity: 1;
    }
    
    &:disabled {
      background: #d1c5dd;
      color: #ffffff;
      border-color: transparent;
      
      &:hover,
      &:active,
      &:focus {
        background: #d1c5dd;
        color: #ffffff;
        border-color: transparent;
      }
    }
    
    &--link {
      background-color: ${(props) => props.theme.primary};
    }
    
    &--fw {
      width: 100%;
    }
  }
  
  button[ant-click-animating-without-extra-node='true']::after,
  button.ant-click-animating-node {
    display: none;
  }
  
  .ant-carousel {
    @media (min-width: ${deviceSizes.laptop}) {
      position: relative;
      width: 100vw;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    }
  }

  .ant-btn-link[disabled] {
    opacity: 0.3;
    
    &:hover {
      color: ${(props) => props.theme.primary};
    }
  }

  .ant-dropdown {
    &-placement-bottomRight {
      & .ant-dropdown-menu {
        top: 10px;
      }
    }
    
    &-menu {
      padding: 20px;
      border-radius: ${(props) => props.theme.borderRadius};
    }

    &-menu-item {
      padding: 5px 0;
      font-weight: 500;
      font-size: 14px;
      
      a {
        color: ${(props) => props.theme.common.black};
      }
      
      &:first-child {
        padding-top: 0;
      }
      
      &:last-child {
        padding-bottom: 0;
      }
      
      &:hover {
        background-color: transparent;
      }
    }
  }
  
  .ant-picker {
    padding: 10px 20px;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #b2b2b2;
    border-radius: 5px;
    
    &:hover {
      border-color: ${(props) => props.theme.primary};
    } 
    
    &-focused {
      box-shadow: unset;
    }
  }
  
  .ant-checkbox {
    top: unset;
  }
  
  .ant-checkbox-wrapper {
    align-items: center;
    width: auto;
  }
  
  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.primary};
    border-color: ${(props) => props.theme.primary}; 
    
    &:after {
      width: 11px;
      height: 7.67px;
      transform: rotateY(180deg) rotateZ(45deg) scale(1) translate(-50%,-50%);
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${(props) => props.theme.primary};
  }
  
  .ant-checkbox-checked:after {
    border: 1px solid ${(props) => props.theme.primary};
    border-radius: 5px;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props) => props.theme.primary};
  }

  .ant-select {
    &-single:not(.ant-select-customize-input) .ant-select-selector {
      .ant-select-selection-search-input {
        height: 48px;
      }

      .ant-select-selection-placeholder {
        line-height: 48px;
      }
    }
    
    &:not(.ant-select-customize-input) .ant-select-selector {
      height: 50px;
      ${inputStyle};
      padding-top: 0;
      padding-bottom: 0;
    }

    & .ant-select-selector {
      height: 50px;
      ${inputStyle};
      padding-top: 0;
      padding-bottom: 0;
    }

    &-single .ant-select-selector .ant-select-selection-item {
      line-height: 48px;
    }

    .ant-select-selection-item {
      line-height: 48px;
    }

    input {
      height: 100%;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background-color: ${(props) => props.theme.primary};
    }
  }

  .ant-picker-header-view button:hover {
    color: ${(props) => props.theme.primary};
  }

  @media screen {
    @media (max-width: ${deviceSizes.tablet}) {
      input, textarea, select {
        font-size: 16px !important;
      }
    }
  }
`;

export const theme: DefaultTheme = {
  common: '#333333',
  primary: '#AC79E0',
  secondary: {
    black: '#2A2323',
    purple: '#efecf5',
    gray: '#eeeeee',
  },
  additional: {
    dividerColor: '#e5e5e5',
    borderColor: 'rgba(99, 62, 142, 0.1)',
  },
  errorColor: '#FB0D54',
  areas: {
    purple: '#CB65DF',
    red: '#F2796B',
    orange: '#FEC185',
    blue: '#8888FF',
  },
  borderRadius: '5px',
  maxWidth: '1232px',
  fonts: ['Gotham Pro', 'DIN Round Pro'],
  fontSizes: {},
  components: {
    h1: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 500,
    },
  },
};
