import React from 'react';
import ReactDOM from 'react-dom/client';
import dayjs from 'dayjs';
import 'dayjs/locale/ru.js';
import locale from 'antd/es/locale/ru_RU';
import { ConfigProvider } from 'antd';
import { CookiesProvider } from 'react-cookie';

import App from './components/app/App';
import { Provider } from 'react-redux';
import { store } from './store/store';
import 'antd/dist/antd.min.css';
import './assets/styles/index.scss';
import { YMInitializer } from 'react-yandex-metrika';

dayjs.locale('ru');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <ConfigProvider locale={locale}>
      <YMInitializer accounts={[51753968]} />
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ConfigProvider>
  </Provider>,
);
