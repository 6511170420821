export const routes = {
  HOME: '/',
  PROMO: '/promo',
  PROMOTIONS: '/promotions',
  CONTACTS: '/contacts',
  CATALOG: '/catalog',
  DELIVERY: '/delivery',
  BONUS_SYSTEM: '/bonus-system',
  ABOUT: '/about',
  PERSONAL: '/personal',
  ADDRESS: `address`,
  HISTORY: `history`,
  POLICY: 'https://api.kebr.xyz/mapi/document/TakeEat/licence_agreement',
  FRANCHISE: 'https://franchising.takeeat.ru/',
  SELECTION: '/selection',
};
